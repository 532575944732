import styled, {createGlobalStyle, css} from 'styled-components';
import {responsive} from 'utils/utility';
import '../index.css';
import {IMarginDivProps} from './styledTypes';
export const supportDeviceSize = 1080;

export const GlobalStyle = createGlobalStyle`

/* CSS Reset */
html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

html {
font-size: 62.5%; // 1rem = 10px 로 변경 한 것
// 참고링크 = https://stackoverflow.com/questions/68790660/setting-root-font-size-not-affecting-rem-units-in-safari-for-margin-padding-et
// 128px => 12.8rem , 4px => 0.4rem 가능!

    /* @media all and (max-width: ${supportDeviceSize}px) {
        font-size: 31.25%;
    } */
}

body {

    background: white;
    margin: 0;
    padding: 0;
    font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif; 
    display: flex;
    flex-direction: column;
    align-items: center;
}

button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font: inherit;
    color: inherit;
  }


`;

/**
 * @param props ml, mr, mt, mb, responsive
 * margin div
 */
export const MarginDiv = styled.div<IMarginDivProps>`
  margin-left: ${props => props.ml || 0}rem;
  margin-right: ${props => props.mr || 0}rem;
  margin-top: ${props => props.mt || 0}rem;
  margin-bottom: ${props => props.mb || 0}rem;

  ${props =>
    props.responsive?.mobile &&
    responsive.mobile(css`
      margin-left: ${props.responsive.mobile.ml || props.ml || 0}rem;
      margin-right: ${props.responsive.mobile.mr || props.mr || 0}rem;
      margin-top: ${props.responsive.mobile.mt || props.mt || 0}rem;
      margin-bottom: ${props.responsive.mobile.mb || props.mb || 0}rem;
    `)}

  ${props =>
    props.responsive?.medium &&
    responsive.medium(css`
      margin-left: ${props.responsive.medium.ml || props.ml || 0}rem;
      margin-right: ${props.responsive.medium.mr || props.mr || 0}rem;
      margin-top: ${props.responsive.medium.mt || props.mt || 0}rem;
      margin-bottom: ${props.responsive.medium.mb || props.mb || 0}rem;
    `)}

  ${props =>
    props.responsive?.desktop &&
    responsive.desktop(css`
      margin-left: ${props.responsive.desktop.ml || props.ml || 0}rem;
      margin-right: ${props.responsive.desktop.mr || props.mr || 0}rem;
      margin-top: ${props.responsive.desktop.mt || props.mt || 0}rem;
      margin-bottom: ${props.responsive.desktop.mb || props.mb || 0}rem;
    `)}
`;

export const screenSizeMobile = '36rem';
export const screenSizeMedium = '128rem';
export const screenSizeDesktop = '192rem';
