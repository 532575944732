import {css} from 'styled-components';
import {JWT_KEY} from '../config/constant';

/**
 * @param {string} email 이메일 정규식 유효성 검사
 * @returns {boolean}
 */
export const isValidEmail = (email: string) => {
  return email.match(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,4}$/i) !== null;
};

export const isValidPassword = (password: string) => {
  return password.match(/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/);
};

/**
 * @param {string} phoneNumber 전화번호 정규식 유효성 검사
 * @returns {boolean}
 */
export const isValidPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.match(/^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/);
};

/**
 * @param {string} input 전화번호 형식으로 정규식 변환
 * @returns {string|undefined}
 */
export const formatPhoneNumber = (input?: string) => {
  return input
    ?.replace(/[^0-9]/g, '')
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
    .replace(/(-{1,2})$/g, '');
};

export const getJwt = () => window.localStorage.getItem(JWT_KEY) ?? '';

export const saveJwt = (jwt: string) => {
  window.localStorage.setItem(JWT_KEY, jwt);
};

export const clearJwt = () => {
  window.localStorage.removeItem(JWT_KEY ?? '');
};

/*export const saveUser = (user: GetUsersUserIdResponse) => {
  window.localStorage.setItem(
    process.env.REACT_APP_LOGIN_INFO ?? "",
    JSON.stringify(user) ?? ""
  );
};*/

export const clearUser = () => {
  window.localStorage.removeItem(process.env.REACT_APP_LOGIN_INFO ?? '');
};

export const getUserEmail = () => {
  const loginInfoValue = window.localStorage.getItem(process.env.REACT_APP_LOGIN_INFO ?? '');
  if (loginInfoValue === null) {
    return '';
  } else {
    const loginInfo = JSON.parse(loginInfoValue);
    return loginInfo.email ?? '';
  }
};

export const getUserName = (): string => {
  const loginInfoValue = window.localStorage.getItem(process.env.REACT_APP_LOGIN_INFO ?? '');
  if (loginInfoValue === null) {
    return '';
  } else {
    const loginInfo = JSON.parse(loginInfoValue);
    return loginInfo.name ?? '';
  }
};

export const responsive = {
  // eslint-disable-next-line
  mobile: (styles: any) => css`
    @media ${({theme}) => theme.media.mobile} {
      ${styles}
    }
  `,
  // eslint-disable-next-line
  medium: (styles: any) => css`
    @media ${({theme}) => theme.media.medium} {
      ${styles}
    }
  `,
  // eslint-disable-next-line
  desktop: (styles: any) => css`
    @media ${({theme}) => theme.media.desktop} {
      ${styles}
    }
  `,
};
