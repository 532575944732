import {DefaultTheme} from 'styled-components';

const size = {
  mobile: '360px',
  mobileMin: '361px',
  mobileMax: '1279px',
  medium: '1280px',
  mediumMax: '1919px',
  desktop: '1920px',
};

const theme: DefaultTheme = {
  media: {
    mobile: `(max-width: ${size.mobile}), (min-width: ${size.mobileMin}) and (max-width: ${size.mobileMax})`,
    medium: `(min-width: ${size.medium}) and (max-width: ${size.mediumMax})`,
    desktop: `(min-width: ${size.desktop})`,
  },
};

export {size, theme};
